.background {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 0 0 50% 50%/0 0 100% 100%;
  transform: scaleX(4);
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 60%));
  z-index: 1;
}

.nestedRings1 {
  transform: rotate(-50deg);
  top: 14%;
}

.nestedRings2 {
  right: -20px;
  bottom: 12%;
  transform: rotate(-110deg);
}

.greenGradient1 {
  top: -15%;
  right: -15%;
}

.greenGradient2 {
  top: 40%;
  left: -15%;
}

.gradientContainer {
  position: absolute;
  z-index: 1;
}

.greenGradBox {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: rgb(36, 187, 134);
  background: radial-gradient(
    circle,
    rgba(36, 187, 134, 0.3) 0%,
    rgba(36, 187, 134, 0) 60%
  );
}

.nestedRings {
  position: absolute;
  width: 400px !important;
  height: 400px !important;
  z-index: 1;
}

.nestedRing {
  border: 1px solid #20b48640;
  width: 100%;
  height: 100%;
  padding: 40px;
  border-radius: 50%;
}

.ringsEllipse {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #606060;
  top: -5px;
  left: 50%;
}

.greenEllipse {
  position: absolute;
  width: 25px;
  height: 25px;
  background: #20b486;
  opacity: 0.6;
  border-radius: 50%;
  z-index: -1;
  top: -20px;
  left: 10px;
}

@media screen and (min-width: 1068px) {
  .background {
    transform: scaleX(3);
  }
}

@media screen and (min-width: 1400px) {
  .background {
    transform: scaleX(2);
  }
}
