.container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container1 {
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.form-container1 h2 {
  margin-bottom: 20px;
  text-align: center;
}

.form-field1 {
  margin-bottom: 15px;
}

.form-field1 input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-field1 .signup-submit {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.form-link1 {
  margin-top: 10px;
  text-align: center;
}
