/* EditData.css */

.edit-data-container2 {
    max-width: 600px;
    margin: 100px auto 0;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group2 {
    margin-bottom: 20px;
  }
  
  .label2 {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="url"],
  select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .select2 {
    appearance: none; /* Remove default select styling */
    background: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23000' d='M4 5.293L1.854 3.146 0.439 4.561l4.293 4.293a1 1 0 0 0 1.414 0l4.293-4.293-1.414-1.414L4 5.293z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
  }
  
  .submit-btn2[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-btn2[type="submit"]:hover {
    background-color: #0056b3;
  }
  