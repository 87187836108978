.section {
  background: #f9f9f9;
  padding: 50px 0 100px;
}

.section h1 {
  font-size: 24px !important;
  font-weight: 700 !important;
  margin-top: 4px;
}

.section .header p {
  font-size: 14px;
  font-weight: 600;
}

.card {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.cardImgContainer {
  flex: 0 0 40%;
}

.cardImgContainer img {
  width: 100%;
}

.cardTextContainer {
  width: 90%;
  flex: 0 0 50%;
}

.card .cardTextContainer h2 {
  font-size: 20px;
}

.card .cardTextContainer p {
  font-size: 16px;
  color: #667085;
}

.dotsGroup {
  position: absolute;
  bottom: -100px;
}

.dotsGroup1 {
  left: -100px;
}

.dotsGroup2 {
  right: -100px;
}

@media screen and (min-width: 560px) {
  .main {
    padding: 48px;
  }

  .card {
    flex-direction: row;
  }
}

@media screen and (min-width: 990px) {
  .card {
    width: 45%;
  }
}
