.videodashboard{
    height: 100%;
    background-color: black;
}
.videoSubjectTitledb{
    display: flex;
    justify-content: space-between;
}
.coursedatadb{
    margin-top: 20px;
}
.unitnamedb{
    background-color: rgb(199, 196, 196);
    color:black;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    align-items: center;
}
.unitnamedb h4{
    padding-left: 5px;
}
.topiclistsdb{
    margin-top: 10px; 
}
.topicdb{
    margin-top: 4px;
}
.dbbutton{
    position: relative;
    right: 0px;
}