.wrapper {
  width: fit-content;
  margin-bottom: 4rem;
}

.card {
  width: 300px;
  height: 400px;
  border-radius: 20px;
  border: 4px solid var(--light-color);
  margin-bottom: 1.5rem;
  position: relative;
}

.card img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1rem;
}

.overlay p span {
  color: var(--primary-f-color);
  font-size: 1.5rem;
}
