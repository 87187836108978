.btn {
  --text-color: #3bc49a;
  background: #eafff9;
  color: var(--text-color);
  font-weight: 700;
  border-radius: 8px;
  font-size: 18px;
}

.btn:hover span small {
  color: var(--text-color);
}
