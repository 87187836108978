.wrapper {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000040;
}

.container {
  position: relative;
  z-index: 102;
  background: #fff;
  width: 100%;
  padding: 60px 20px;
}

.wrapper .container h1 {
  color: #242731;
  font-size: 36px;
  font-weight: 700;
}

.wrapper .container p {
  color: #575f6e;
  font-size: 18px;
  font-weight: 100;
}

.wrapper .container p strong {
  font-weight: 700;
}

.backBtn {
  top: 25px;
  right: 25px;
  border: 1px solid #000;
  padding: 5px 30px;
  color: #242731;
  font-weight: 600;
  font-size: 18px;
  border-radius: 6px;
}

.backBtn:hover {
  background: #242731;
  color: #f4f4f4;
}

.form label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form label input {
  color: #242731;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 16px;
}

.form .submitBtn {
  background: #4277ff;
  width: 75%;
  margin: 0 auto;
}

.form .submitBtn small {
  padding: 6px 0;
}

@media screen and (min-width: 600px) {
  .container {
    width: 550px;
    padding: 60px 40px;
  }
}
