.wrapper {
}

.input {
  border-radius: 12px 0 0 12px;
  background: transparent;
  border: 1px solid rgba(159, 159, 159, 0.4);
  border-right: 0;
  color: var(--light-color);
  padding: 12px 26px;
  width: 100%;
}

.input:active,
.input:focus {
  border-color: rgba(159, 159, 159, 0.8);
}

.input::placeholder {
  color: rgba(159, 159, 159, 0.4);
  opacity: 1; /* Firefox */
}

.input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: rgba(159, 159, 159, 0.4);
}

.button {
  border-radius: 0px 12px 12px 0px;
  background: linear-gradient(98deg, #2a6bf2 -44.89%, #2d3794 95.32%);
  color: var(--light-color);
  text-wrap: nowrap;
  border: none;
  padding: 0px 27px;
}

.button span small {
  font-weight: 400;
  font-size: 1rem;
  padding: 13px 0;
}

.button:hover {
  transform: none;
  box-shadow: none;
}
