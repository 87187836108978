.detailsdropdownbutton{
    width:100%;
    height:50px;
    border:2px 4px black !important;
    margin-bottom: 2px;
    background-color: rgb(214, 213, 213);
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
    justify-content: space-between;
    display: flex;
    align-content: center;
    padding-top: 10px;
    padding-left: 5px;
}
.unitdata p{
    color:white;
    width:95%;
    padding: 1px;
    margin-top: 10px;
    height:auto;
    align-content: center;
    justify-content: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
    box-shadow: 0px 1px rgba(0,0,0,0.19);
    border: 1px solid rgb(255, 255, 255);
}
.unitdata p:hover{
    background-color: rgb(215, 215, 215);
    color:black
    
}
.unitdata{
    padding: 0;
    margin-bottom: 20px;
}