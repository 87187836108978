.videoSec iframe,.skeleton{
    width:65vw ;
    height: 80vh;
    border:5px black solid;
    border-radius: 10px;
}
.ptag{
    animation: pop  2s infinite ;
    color: rgb(186, 112, 255)
}
@keyframes pop
{
  0%{transform:scale(1);}
  50%{transform:scale(1.2);
    text-shadow: rgba(247, 255, 98, 0.4) 1px 0 10px;
}
  100%{transform:scale(1);
    
}
}
/* media query */
@media (max-width:768px) {
    .videoSec iframe,.skeleton{
        width: 90vw ; 
        margin-left: 5px;
        height: 60vh;
    }
}