@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  width: fit-content;
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
}

.spinner svg {
  filter: blur(2px);
}
