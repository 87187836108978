.main-body {
    font-family: Helvetica, sans-serif;
    background: #1F1D0D;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

.loading-indicator {
  font-size: 20px; 
  text-align: center;
  padding: 20px;
  background-color: rgba(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.loading-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 20px;
}

.loading-message {
  opacity: 0;
  transform: translateY(50px); /* Initial position for the animation */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.loading-message.active {
  opacity: 1;
  transform: translateY(0); /* Final position for the animation */
}

.newslatter_box{
    background-color: #705792;
    box-shadow: 10px 10px 10px #382045;
}
  
  .dropdown {
    min-width: 15em;
    position: relative;
    margin: 2em;
  }
  
  .dropdown * {
    box-sizing: border-box;
  }
  
  .select {
    background: #AF69EE;
    color: #050502;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.35em;
    padding: 1em;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .select-clicked {
    border: 2px solid #0a0a04;
    box-shadow: 0 0 0.8em #1F1D0D;
  }
  
  .select:hover {
    background: F29CA3;
  }
  
  .caret {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid;
    transition: 0.3s;
  }
  
  .caret-rotate {
    transform: rotate(180deg);
  }
  
  .menu {
    list-style: none;
    padding: 0.2em 0.5em;
    background: #fff;
    border: 1px #922212 solid;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
    border-radius: 0.5em;
    color: #202020;
    position: absolute;
    top: 3em;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    display: none; /* Set display to none to hide by default */
    z-index: 1;
  }
  
  .menu li {
    padding: 0.7em 0.5em;
    margin: 0.3em 0;
    border-radius: 0.5em;
    cursor: pointer;
  }
  
  .menu li:hover {
    background: #D7C5FD;
  }
  
  .active {
    background: #fff;
  }
  
  /* Show the dropdown when .menu-open class is added */
  .menu-open {
    display: block;
    opacity: 1;
  }
  
  
  
  
  
  #dropdown-container {
    background-color: #1F1D0D;
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }


  .container h1 {
    color: white;
    font-size: 50px;
    text-align: center;
  }



    .input-group {
    background-color: #ccc;
    display: inline-block;
    margin: 50px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;

.input-group-checkbox p {
  display: inline-block;
}

.input-group-checkbox div {
  display: flex;
  float: right;
}

.gap{
margin: 0 20px;
}

.checkbox-dabba {
  margin: 0 7px;
}
 
  
}

#submitBtn{
  background-color: #CE4257; /* Green */
  border: none;
  color: white;
  padding: 15px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 5px;
}

#submitBtn:hover{
  color: #CE4257;
  border: 2px solid #CE4257;
  background-color: whitesmoke;
  font-weight: 700;
}

  .button-div{
    text-align: center;
    margin-bottom: 50px;
  }


  /* ... Your existing CSS rules ... */

/* Adjustments for smaller screens */
@media (max-width: 912px) {
  .dropdown {
    min-width: 240px;
    margin: 1em auto;
  }

  .select {
    padding: 0.8em;
  }

  .menu {
    top: 2.5em;
  }

  #dropdown-container {
    grid-template-columns: 1fr;
  }
}

.course-card {
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  -webkit-transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  border: 2px solid #D2D2D2;
}

.card-link {
  text-decoration: none;
  color: black;
}

.card-link:hover {
  text-decoration: underline;
}

.card-link:visited {
  color: black;
}


/* Add this media query for larger screens */
@media (min-width: 768px) {
  #coursesList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}


.course-card:hover {
  z-index: 1;
  border-color: #AF69EE;
  -webkit-transform: translate(-2px, -2px);
  transform: translate(-2px, -2px);
  -webkit-box-shadow: 10px 10px 0 0 #AF69EE;
  box-shadow: 10px 10px 0 0 #AF69EE;
}


.subjects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.subject-rectangle {
  width: calc(33.33% - 30px); /* 3 containers in a row, accounting for margins */
  position: relative;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #1F1D0D;
  border-radius: 5px;
  padding: 20px 20px;
  margin-bottom: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subject-rectangle:hover {
  z-index: 1;
  border-color: #AF69EE;
  -webkit-transform: translate(-2px, -2px);
  transform: translate(-2px, -2px);
  -webkit-box-shadow: 10px 10px 0 0 #AF69EE;
  box-shadow: 10px 10px 0 0 #AF69EE;
}


/* Media Query for Small Screens (e.g., smartphones) */
@media (max-width: 768px) {
  .subjects-container {
    flex-direction: column; /* Display containers in a column layout */
    align-items: center; /* Center containers horizontally */
  }

  .subject-rectangle {
    width: 100%; /* 1 container in a row */
  }
}
