.learningtab{
    margin-top:5px;
    padding-left: 10px;
    display: flex;
    background-color: black;
    height: 100vh;
}
/* media query */
@media (max-width:768px) {
    .learningtab{
        flex-direction: column;
        padding-left: 0px;
        margin-left: 0px;
    }
}