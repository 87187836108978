.memberWrapper {
  min-width: 320px;
  max-width: 500px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .membersWrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .memberWrapper {
    padding: 0;
    margin: 18px 0;
  }
  .memberWrapper > div {
    padding: 0 0 18px;
  }
  .memberWrapper a {
    border-radius: 0 !important;
  }
  .memberContent {
    padding: 0 12px;
  }
}
