.container {
  width: 80%;
  margin: 0 auto;
}

.container img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 500px) {
  .container {
    width: 382px;
  }
}
