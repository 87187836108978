.button {
  border-radius: 7.6px;
  background: var(--secondary-btn-color);
  color: var(--light-color);
  border: none;
}

.button span small {
  font-weight: 500;
  font-size: 1rem;
}

.button:hover {
  cursor: pointer;
  box-shadow: 0 1px 32px 0 rgba(164, 135, 255, 0.4);
  -webkit-box-shadow: 0 1px 32px 0 rgba(164, 135, 255, 0.4);
}
