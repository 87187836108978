.section {
  background: #f9f9f9;
  padding: 60px 0;
  margin-bottom: 100px;
}

.section > div {
  flex-direction: column;
  gap: 60px;
}

.section .textContainer p {
  color: var(--secondary-text-color);
  font-weight: 400;
  font-size: 16px;
}

.section strong {
  font-weight: 800;
}

.textContainer {
  width: 100%;
}

.imgContainer img {
  width: 350px;
  height: 350px;
}

@media screen and (min-width: 740px) {
  .section > div {
    flex-direction: row;
    gap: 0;
  }

  .textContainer {
    width: 50%;
  }
  .section .textContainer p {
    font-size: 20px;
  }
}
