.videosection{
    background-color: white;
    height: 100vh;
}
.videosection h1{
    margin-top: 5px;
    text-align: center;
    font-size: 20px;
    color:black;

}
.searchimage{
    margin: auto;
    align-items: center;
}
.searchimage img{
    width:75vw;
    height:70vh;
}
.subjects-container.video .subject-rectangle{
    background-color: rgb(215, 214, 214);
    font-weight: bold;
}
@media (max-width:1660px) {
    .searchimage{
        margin-left:15vw;
        align-items: center;
    }
}
@media (max-width:375px) {
    .searchimage{
        margin-left:10vw;
        align-items: center;
    }
    .searchimage img{
        width:100vw;
        height:50vh;
    }  

}
@media (max-width:426px) {
    .searchimage{
        margin-left:10vw;
        align-items: center;
    }
    .searchimage img{
        width:100vw;
        height:40vh;
    }  

}