.unitlists{
    width:95%;
    padding-top: 0;
    margin-top: 0;
    background-color: rgb(0, 0, 0);
    height: 100vh;
    margin-right: 5px;
    padding-left: 5px;
}
.unitlistheader{
    padding-top: 0px;
    margin-top: 0px;
    background-color: rgb(162, 59,236);
    color: white;
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
    font-weight: bold;
    padding-left: 15px;
    height: 50px;
}
.unitlistheader p{
    padding-top: 13px;
    
}

/* media query */
@media (max-width:768px) {
    .unitlists{
        width:95%;
    }
}