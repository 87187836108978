.Searchbar{
    height:90px;
    width: 75vw;
    background-color: #484343;
    margin:auto;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
}
.SearchBarOptions{
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left:0px ;
}
.searchoptionliselect{
    background-color: #1F1D0D;
}
.SearchBarOptions li{
    width:300px;
    padding-left: 10px;
    list-style: none;
}
.searchsubmitbutton{
    background-color: white;
    margin: auto;
    width: 100px;
    height:40px;
    border-radius: 10px;
    font-weight: bold;
    color: white;
  border-color: #419396;
  background-color: #3c8880;
}
.searchsubmitbutton:hover{
    color:#ffffff;;
  -webkit-box-shadow: 0 8px 24px 0 rgba(247, 255, 98, 0.4);
          box-shadow: 0 8px 24px 0 rgba(247, 255, 98, 0.4);
}
@media (max-width:1260px) {
    .SearchBar{
        width: 80vw;
    }
    .SearchBarOptions li{
        width:200px;
        padding-left: 10px;
    }
    .searchsubmitbutton{
        width: 100px;
        height:40px;
    }
}
@media (max-width:1000px) {
    .Searchbar{
        flex-direction: column;
        height: auto;
        width: auto;
    }
    .SearchBarOptions{
        flex-direction: column
        ;
    }
    .SearchBarOptions li{
        margin: auto;
        width:500px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0px;
    }
    .searchsubmitbutton{
        width: 140px;
        height:40px;
        margin-bottom: 10px;
        font-size: 16px;
    }
}
@media (max-width:610px) {
    .Searchbar{
        flex-direction: column;
        height: auto;
        width: auto;
    }
    .SearchBarOptions{
        flex-direction: column
        ;
    }
    .SearchBarOptions li{
        margin: auto;
        width:300px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .searchsubmitbutton{
        width: 140px;
        height:40px;
    }
}
@media (max-width:375px) {
    .Searchbar{
        flex-direction: column;
        height: auto;
        width: auto;
    }
    .SearchBarOptions{
        flex-direction: column
        ;
    }
    .SearchBarOptions li{
        margin: auto;
        width:200px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .searchsubmitbutton{
        width: 140px;
        height:40px;
    }
}
