.section h2 {
  width: 100%;
}

.card {
  width: 100%;
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 15px;
  margin: 0 auto;
  background: #fff;
  border-radius: 12px;
}

.card .cardPara {
  font-size: 14px;
  line-height: 150%;
  color: #646464;
}

.activeSlide .card {
  background: var(--secondary-green-color);
}

.section .activeSlide .cardHeadingContainer h1,
.activeSlide .cardPara,
.activeSlide span,
.activeSlide svg {
  color: #fff;
}

.activeSlide .cardHeadingContainer h1 {
  font-size: 20px !important;
  font-weight: 800;
}

.cardHeadingContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.card .cardHeadingContainer h1 {
  font-size: 24px;
  font-weight: 700;
  color: var(--default-text-color);
}

.cardLearnMoreContainer {
  font-size: 16px;
  font-weight: 600;
  color: var(--secondary-green-color);
  display: flex;
  align-items: center;
  gap: 10px;
  justify-self: flex-end;
}

.cardLearnMoreContainer:hover {
  color: var(--secondary-green-color);
}

.cardLearnMoreContainer svg {
  font-size: 24px;
  transform: translateY(2px);
}

@media screen and (min-width: 420px) {
  .card {
    width: 360px;
  }
  .cardHeadingContainer {
    gap: 15px;
  }
  .card .cardPara {
    font-size: 16px;
  }
  .cardLearnMoreContainer {
    font-size: 18px;
  }
}

@media screen and (min-width: 990px) {
  .section h2 {
    width: 50%;
  }
}
