.btn {
  --btn-color: #22b286;
  width: fit-content;
  background: var(--btn-color);
  border-radius: 50px;
  padding: 0 30px;
}

.btn span small {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}

.btn,
.btn p,
.btn span,
.btn strong,
.btn:hover {
  color: #fff !important;
}

.btn,
.btn p {
  font-size: 16px !important;
  font-weight: 600 !important;
  text-wrap: nowrap !important;
}

.btn svg {
  transform: translateY(1px);
  font-size: 18px;
}
